import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1000.000000 1000.000000" >
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1000.000000) scale(0.100000,-0.100000)" >

<path d="M5580 8434 c-317 -141 -396 -178 -420 -194 -33 -22 -86 -40 -121 -40
-16 0 -48 -6 -71 -14 -24 -7 -63 -19 -88 -26 -141 -39 -370 -142 -485 -218
-257 -168 -511 -411 -640 -612 -16 -25 -40 -58 -53 -74 -32 -40 -120 -192
-180 -311 -80 -158 -141 -327 -188 -520 -8 -33 -19 -76 -24 -95 -44 -170 -66
-485 -51 -699 11 -140 24 -254 35 -296 3 -11 12 -51 21 -90 21 -88 101 -328
124 -369 10 -17 37 -69 61 -116 108 -211 244 -374 447 -537 81 -64 278 -176
349 -199 l53 -17 36 32 c20 17 41 31 48 31 12 0 67 59 67 73 0 5 46 66 103
135 118 145 263 361 318 472 48 99 91 209 87 222 -3 7 -40 11 -93 12 -195 3
-343 68 -479 209 -131 137 -197 262 -241 451 -64 276 -23 588 113 861 77 155
211 319 343 419 54 41 59 51 59 118 0 28 4 67 9 87 5 20 20 77 32 126 12 50
26 97 30 105 4 8 18 44 30 80 29 86 88 203 156 309 31 47 58 88 62 91 4 3 14
17 23 31 21 33 95 115 188 209 125 124 271 235 415 314 69 38 145 89 145 99 0
23 -88 0 -220 -59z"/>
<path d="M5608 8118 c-179 -209 -366 -519 -427 -707 -27 -83 -54 -207 -55
-251 l-1 -35 115 -7 c126 -9 162 -17 260 -61 229 -103 401 -331 459 -607 33
-158 39 -291 20 -409 -13 -77 -53 -242 -75 -309 -20 -60 -102 -226 -123 -249
-9 -10 -25 -31 -35 -48 -51 -80 -165 -201 -254 -268 -32 -24 -55 -50 -58 -66
-31 -140 -150 -417 -227 -531 -220 -324 -446 -522 -752 -660 -145 -64 -258
-124 -263 -138 -5 -15 126 -6 213 15 98 24 277 82 310 102 23 13 63 19 163 24
73 4 165 12 205 17 115 17 399 113 507 173 30 17 80 44 110 60 30 17 69 41 86
54 17 13 36 23 42 23 7 0 12 4 12 8 0 5 10 13 23 19 12 6 40 26 63 44 23 19
53 43 67 55 130 102 367 369 479 539 164 251 294 555 362 845 52 224 68 369
68 605 -1 289 -29 475 -112 728 -31 94 -33 97 -109 260 -156 333 -464 629
-810 776 -45 19 -181 60 -203 61 -3 0 -30 -28 -60 -62z"/>
<path d="M4914 6446 c-3 -8 -4 -31 -2 -52 3 -36 5 -39 33 -39 28 0 30 2 33 44
3 46 -7 61 -41 61 -10 0 -20 -6 -23 -14z"/>
<path d="M4720 6367 c0 -32 91 -77 115 -57 23 19 19 27 -28 55 -56 32 -87 33
-87 2z"/>
<path d="M5086 6345 c-48 -17 -60 -29 -52 -51 7 -18 44 -16 99 5 61 24 17 68
-47 46z"/>
<path d="M4900 6268 c-8 -22 -51 -497 -50 -570 0 -20 6 -38 13 -41 6 -2 52 5
101 15 48 11 92 16 96 11 4 -4 22 -46 40 -93 56 -143 77 -180 104 -180 42 0
167 32 172 44 3 7 -20 72 -51 146 -30 73 -52 136 -48 140 5 4 43 14 86 24 49
11 81 24 84 33 7 17 -4 28 -267 274 -217 204 -233 218 -253 219 -9 0 -22 -10
-27 -22z m170 -183 c41 -39 113 -107 160 -150 46 -44 90 -86 98 -93 20 -19 7
-27 -65 -42 -35 -7 -67 -18 -72 -24 -7 -8 5 -48 35 -121 77 -186 75 -171 28
-181 l-30 -6 -54 132 c-31 75 -64 138 -75 146 -17 11 -31 10 -95 -6 -41 -10
-76 -17 -78 -15 -4 4 27 395 34 433 6 29 6 28 114 -73z"/>
<path d="M4738 6223 c-40 -16 -47 -33 -23 -53 11 -9 25 -8 64 5 27 9 51 21 54
28 14 38 -30 48 -95 20z"/>
<path d="M4856 3022 c-10 -2 -16 -14 -16 -32 0 -25 5 -31 40 -41 l40 -12 -2
-341 -3 -340 -37 -9 c-35 -8 -38 -12 -38 -43 l0 -34 126 0 125 0 -3 33 c-2 27
-9 34 -38 43 l-35 12 -3 96 -3 96 29 0 c30 0 36 -7 174 -201 25 -37 54 -69 63
-73 27 -10 113 -7 120 5 11 17 -4 57 -23 62 -9 3 -28 8 -42 11 -17 4 -47 38
-95 107 -38 55 -76 107 -83 115 -18 19 -17 20 62 113 54 63 78 83 108 91 35
10 38 14 38 45 l0 35 -105 0 -105 0 0 -31 c0 -19 7 -37 17 -44 16 -12 11 -20
-51 -89 -108 -121 -106 -123 -106 173 l0 249 -69 4 c-38 2 -76 2 -85 0z"/>
<path d="M3762 2988 c3 -28 8 -33 41 -41 l37 -9 0 -339 c0 -263 -3 -340 -12
-343 -7 -3 -25 -7 -40 -10 -24 -6 -28 -11 -28 -41 l0 -35 120 0 120 0 0 34 c0
35 -13 46 -51 46 -18 0 -19 12 -19 385 l0 385 -86 0 -85 0 3 -32z"/>
<path d="M4135 2995 c-31 -30 -32 -73 -5 -103 42 -45 110 -11 110 54 0 23 -8
39 -26 53 -34 27 -50 26 -79 -4z"/>
<path d="M6460 2992 c0 -24 6 -31 40 -45 l40 -15 0 -370 0 -371 58 -12 c31 -8
95 -13 142 -14 92 0 129 13 183 64 114 105 112 391 -3 494 -69 63 -168 60
-248 -9 l-42 -36 0 171 0 171 -85 0 -85 0 0 -28z m394 -333 c39 -31 60 -114
54 -214 -11 -166 -83 -224 -240 -192 l-38 8 0 167 c0 184 3 196 64 233 43 26
125 26 160 -2z"/>
<path d="M2735 3001 c-65 -17 -108 -42 -158 -93 -62 -62 -92 -119 -112 -214
-19 -88 -19 -126 0 -213 29 -137 99 -233 211 -286 51 -25 71 -29 149 -29 78
-1 98 3 150 27 99 46 161 119 201 237 14 42 19 81 18 165 -1 96 -4 119 -29
178 -36 88 -107 167 -183 203 -45 21 -75 28 -137 30 -44 2 -93 -1 -110 -5z
m200 -110 c52 -24 99 -74 127 -136 34 -76 38 -238 8 -318 -40 -107 -113 -165
-220 -174 -113 -11 -214 49 -262 153 -19 42 -22 67 -22 169 -1 105 2 126 22
170 49 106 123 155 237 155 47 0 83 -6 110 -19z"/>
<path d="M3451 2761 c-104 -27 -173 -129 -180 -265 -2 -44 2 -103 8 -132 38
-168 191 -245 341 -172 70 35 84 56 59 89 -15 20 -21 22 -36 12 -76 -53 -152
-63 -201 -27 -52 39 -75 97 -76 193 -1 73 3 93 24 135 35 70 67 91 140 91 33
0 64 -5 70 -11 6 -6 14 -30 18 -55 7 -38 11 -44 32 -44 24 0 25 3 28 76 l3 77
-48 16 c-60 21 -138 28 -182 17z"/>
<path d="M4518 2754 c-58 -18 -112 -69 -141 -133 -17 -37 -21 -66 -21 -151 -1
-94 2 -111 27 -164 46 -99 107 -140 207 -139 70 0 118 17 163 56 32 29 33 38
6 63 -16 15 -23 16 -37 6 -48 -35 -104 -53 -143 -47 -87 15 -129 89 -129 229
1 143 52 211 161 211 67 0 89 -15 89 -59 0 -36 15 -56 42 -56 16 0 18 10 18
79 0 76 -1 79 -27 89 -88 34 -143 38 -215 16z"/>
<path d="M5545 2754 c-55 -19 -102 -75 -111 -129 -14 -94 36 -154 161 -196 39
-13 80 -33 93 -46 32 -32 29 -77 -8 -115 -29 -29 -34 -30 -100 -26 -38 2 -72
6 -75 9 -3 4 -9 26 -13 50 -6 38 -11 44 -35 47 l-27 3 0 -79 0 -79 38 -11 c65
-20 145 -25 192 -12 86 23 151 121 136 204 -11 60 -55 99 -149 135 -46 17 -93
40 -105 51 -49 45 -9 118 68 127 25 3 57 0 73 -6 22 -9 27 -17 27 -45 0 -25 6
-38 20 -46 33 -17 40 -3 40 74 l0 73 -58 17 c-70 19 -113 19 -167 0z"/>
<path d="M4054 2746 c-11 -29 2 -51 36 -62 l35 -12 3 -211 2 -211 -24 0 c-31
0 -58 -26 -54 -54 3 -20 9 -21 126 -24 l122 -3 0 35 c0 31 -3 35 -37 43 l-38
9 -3 252 -2 252 -80 0 c-61 0 -82 -4 -86 -14z"/>
<path d="M5842 2728 c2 -28 9 -35 38 -44 l35 -12 5 -188 c6 -213 17 -252 83
-293 72 -44 137 -40 218 14 28 19 53 35 55 35 2 0 4 -9 4 -19 0 -46 9 -51 86
-51 l74 0 0 35 c0 30 -4 36 -27 41 -53 13 -52 9 -52 267 l0 242 -82 0 c-72 0
-82 -2 -85 -19 -4 -30 7 -46 40 -54 l31 -8 3 -174 c2 -168 2 -174 -20 -198
-54 -57 -145 -71 -193 -29 -37 32 -44 78 -45 295 l0 192 -86 0 -85 0 3 -32z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
